import './owl.carousel';

(function ($) {
  $('.main-carousel').owlCarousel({
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
    }
  });
})(jQuery);